<template>
  <div class="container" >
    <p> <br>  </p>
    <h1 style="color:#fff;">{{ $t("messages.title") }}</h1>
    
    <h3>
      <p>
        {{ $t("messages.slog") }}
      </p>
    </h3>
    <h2 style="color:#fff" >{{ $t("messages.public_data") }}</h2>
    <h5 style="color:#fff" >{{ $t("messages.data_tips") }}</h5>
    <div class="form_card">

    <van-form class=”center“ @submit="onSubmit">
      <van-uploader v-model="fileList" :after-read="afterRead" :max-count="1" />


    <van-cell-group inset>
      <van-field
        v-model="petname"
        name="Pet Name"
        :label="$t('messages.pet_name')"
        :placeholder="$t('messages.pet_name')"
        :rules="[{ required: true, message: $t('messages.pet_name_tips') }]"
      />
      <van-field
        readonly
        clickable
        is-link
        name="datetimePicker"
        v-model="birthday"
        :label="$t('messages.birthday')"
        :placeholder="$t('messages.birthday_tips')"
        @click="showPickdate = true"
      />


      <van-field
        v-model="weight"
        name="Weight"
        :label="$t('messages.weight')"
        :placeholder="$t('messages.weight')"
        :rules="[{ required: false, message: $t('messages.weight_tips') }]"
      />
      <van-field
        v-model="phone"
        name="Phone"
        :label="$t('messages.phone')" 
        type="digit"
        :placeholder="$t('messages.phone')"
        :rules="[{ required: false, message: $t('messages.phone_tips') }]"
      />
      <van-field
        v-model="email"
        name="Email"
        :label="$t('messages.email')"
        :placeholder="$t('messages.email_tips')"
        :rules="[{ required: false, message: $t('messages.email_tips') }]"
      />

      <van-field
        v-model="note"
        name="Note"
        :label="$t('messages.note')"
        :placeholder="$t('messages.note_tips')"
        type="textarea"
        rows="5"
        autosize
        :rules="[{ required: false, message: $t('messages.note_tips') }]"
      />

    </van-cell-group>

      <h2 style="color:#fff" >{{ $t("messages.modify_key") }}</h2>
      <h5 style="color:#ffff00" >{{ $t("messages.modify_key_tips") }}</h5>
      <van-cell-group inset>
      <van-field
        v-model="modify_key"
        name="Modify key"
        :label="$t('messages.modify_key')"
        :placeholder="$t('messages.modify_key')"
        :rules="[{ required: true, message: $t('messages.modify_key_ruler') }]"
      />
      </van-cell-group>

     


    <div style="margin: 16px;">
    <van-button round block type="primary" native-type="submit" 
    >
    {{ $t("messages.submit") }}
    </van-button>
    </div>
  </van-form>
  </div>

  <van-popup v-model:show="showPickdate" style="width: 90%;">
    <van-date-picker
          v-model="currentDate"
          :title="$t('messages.birthday_select')"
          :max-date="maxDate"
          :min-date="minDate"
          @confirm="onConfirm"
          @cancel="showPickdate = false"
          :confirm-button-text="$t('messages.ok')"
          :cancel-button-text="$t('messages.cancel')"
        />
  </van-popup>


  
  <h5-cropper ref="cropper"  :option="option" hide-input= "true" @get-file="getFile"
  @canceltailor="canceltailor"
  ></h5-cropper>



  <!-- 圆角弹窗（居中） -->
  <van-popup v-model:show="loading_state" round :style="{ padding: '64px' }" >
    <van-loading color="#0094ff" />
  </van-popup>


  </div>
</template>

<script>
import { Form, Field, CellGroup,Button,Uploader,Popup,DatePicker,Loading} from 'vant';
import 'vant/lib/index.css';
import { ref } from 'vue';
import {storage,db} from "../main.js";
import {ref as refs, uploadBytesResumable,getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore"; 
import {md5 } from 'js-md5';
import H5Cropper  from 'vue-cropper-h5'
import "vue-cropper-h5/dist/style.css";






export default {
  name: 'PetCreate',
  props: {
    info: Object,
    uid:String
  },
  
  setup() {
    
    var birthday = ref('');
    var date = new Date()
    const currentDate = ref([date.getFullYear()+"", (date.getMonth() + 1)+"", date.getDate()+""]);

    const showPickdate = ref(false);
    const onConfirm = ({ selectedValues }) => {
      birthday.value = selectedValues.join('/');
      showPickdate.value = false;
    };

    return {
      birthday,
      onConfirm,
      showPickdate,
      currentDate,
      maxDate:date,
      minDate: new Date(2000, 0, 1),
      option: {
        outputType:"jpeg",
        cancelButtonText:"Cancel",
        confirmButtonText:" OK ",
        confirmButtonBackgroundColor:"#5072ff",
        ceilbutton:"true"

      }, //配置

    };
  },
  components:{
    [Form.name]:Form,
    [Field.name]:Field,
    [CellGroup.name]:CellGroup,
    [Button.name]:Button,
    [Uploader.name]:Uploader,
    [DatePicker.name]:DatePicker,
    [Popup.name]:Popup,
    [Loading.name]:Loading,
    H5Cropper
  },
  created(){
    this.initeditInfo()
   },
  data(){
    return{
      petname:"",
      weight:"",
      phone:"",
      email:"",
      note:"",
      modify_key:"",
      image_ref:"",
      loading_state:false,
      fileList:[],
      showcrop:false,
      img:"",
      filee:{},
      index:0,
    }

  },
  methods: {
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    initeditInfo(){
      if(this.info != undefined){
        this.petname = this.info.Petname;
        this.weight = this.info.Weight;
        this.phone = this.info.Phone;
        this.email = this.info.Email;
        this.note = this.info.Note;
        this.birthday = this.info.Birthday;
        this.image_ref = this.info.Image;
        if(this.info.Image != ""){
                getDownloadURL(refs(storage,this.info.Image)).then((downloadURL) => {
                  console.log('File available at', downloadURL);
                  this.fileList.push({url:downloadURL})
                  this.imageUrl = downloadURL
                });
            }
        }
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}/`;
      } else if (type === 'month') {
        return `${val}/`;
      }
      return val;
    },

 
// 压缩图片的函数
    compressImage(file, maxWidth, maxHeight, quality, outputFormat) {
      return new Promise((resolve, reject) => {
        // 创建FileReader读取文件
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          const img = new Image();
          img.src = e.target.result;
      
          img.onload = () => {
            // 创建Canvas用于压缩图片
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
            const width = img.width * ratio;
            const height = img.height * ratio;
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
    
            // 将Canvas的内容转为blob
            canvas.toBlob(blob => {
              resolve(blob);
            }, outputFormat || file.type, quality);
          };
        };
        reader.onerror = error => reject(error);
      });
    },
    afterRead(filee) {
      var file = filee.file
      //this.showcrop = true;
      this.$refs.cropper.loadFile(file);
      this.filee = filee
      this.index = 0;
     
    },
    canceltailor(){
      this.fileList.length = 0;
    },
    getFile(file){
      this.index++;
      if(this.index <= 1){
        return
      }

    console.log('Upload is running'+file);
        // 此时可以自行将文件上传至服务器
      const filepath = 'images/dog/'+new Date().getTime()+file.name+'.jpg'
      const storageRef = refs(storage, filepath);
      const metadata = {
        contentType: 'image/jpeg',
      };

      this.filee.file = file
      var filee = this.filee
      filee.status = 'uploading';
      filee.message = 'uploading...';


      this.compressImage(file, 1024, 1024, 0.85, 'image/jpeg').then(compressedFile => {
        const uploadTask =  uploadBytesResumable(storageRef, compressedFile,metadata)
      uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        }, 
        (error) => {
          console.log('File available at', error);
          filee.status = 'failed';
          filee.message = 'Upload Failed';

          // Handle unsuccessful uploads
        }, 
        () => {
          filee.status = 'done';
          this.image_ref = filepath;
          if(filepath != ""){
                getDownloadURL(refs(storage,filepath)).then((downloadURL) => {
                  console.log('File available at', downloadURL);
                  this.fileList.length = 0;
                  this.fileList.push({url:downloadURL})
                });
            }
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        },)


      }).catch(error => {console.error(error)
         filee.status = 'failed';
          filee.message = 'Upload Failed';
      });

      // 'file' comes from the Blob or File API

    },
    async onSubmit(){
      try {
        this.loading_state = true
        await setDoc(doc(db, "petInfo",this.uid), {
        Petname: this.petname,
        Birthday: this.birthday,
        Image: this.image_ref,
        Weight: this.weight,
        Phone: this.phone,
        Email: this.email,
        Note:this.note,
        Time: new Date().getTime(),
      });
      await setDoc(doc(db, "key",this.uid), {
        Modify_key:md5(this.modify_key),
      });
      this.$emit('getinfo');
      
      
    } catch (e) {
     
      console.error("Error adding document: ", e);
    }
    this.loading_state = false

      
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
:deep().btndiv{
    height: 13.333vw;
    color: #fff;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0 4vw;
    line-height: 13.333vw;
    font-size: 4vw;
    position: relative;
    z-index: 600;
}
.container{
  background-color: #5072ff;
  color: #fff;
  padding-bottom:30px;
  width:100vw;

}
.form_card{
  display: flex;
  flex-direction: column;

}
.center {
  justify-content: center;
  background-color: #fff;
  width: 95vw;
}

</style>
