// en.js 英文配置文件
export default {
    'messages': {
      'tips': 'ID de etiqueta faltante o incorrecto Escanee el código QR nuevamente',
      'title':'Etiqueta para mascota MegaC',
      'slog':'Comience a crear su etiqueta de mascota personal',
      'public_data':'Datos públicos',
      'data_tips':'Estos datos serán visibles escaneando la etiqueta QR',
      'pet_name':'Nombre de mascota',
      'pet_name_tips':'Por favor ingrese el nombre de la mascota',
      'birthday':'Cumpleaños',
      'birthday_tips':'Seleccionar cumpleaños de mascota',
      'weight':'Peso',
      'weight_tips':'Por favor ingrese el peso de la mascota',
      'phone':'Teléfono',
      'phone_tips':'Por favor ingrese el número de teléfono del dueño de la mascota.',
      'email':'Email',
      'email_tips':'Por favor ingrese el correo electrónico del dueño de la mascota',
      'note':'Nota',
      'note_tips':'Por favor escribe algo para presentar a tu perro.',
      'modify_key':'Modificar clave',
      'modify_key_tips':"Esta clave se utiliza para modificar los datos públicos, puedes ingresar cualquier cosa pero debes recordarla. de lo contrario no podrás modificar los datos públicos.",
      'modify_key_ruler':'Por favor ingrese la clave Modificar',
      'modify_info':'Modificar información de mascota',
      'submit':'Entregar',
      'birthday_select':'Cumpleaños selecto',
      'ok':'Confirmar',
      'cancel':'Cancelar',
      'call_onwer':'Llamar a mi dueño',
      'send_email':'Enviar correo electrónico',
      'contact_info':'Datos de contacto',
      'key_error':'La clave de modificación es incorrecta',


    }
  }