// en.js 英文配置文件
export default {
    'messages': {
      'tips': 'Missing or incorrect Tag ID Please scan the QR code again',
      'title':'MegaC Pet Tag',
      'slog':'Start to create your personal pet tag',
      'public_data':'Public Data',
      'data_tips':'This data will visible by scanning the QR Tag',
      'pet_name':'Pet Name',
      'pet_name_tips':'Please input pet name',
      'birthday':'Birthday',
      'birthday_tips':'Select Pet Birthday',
      'weight':'Weight',
      'weight_tips':'Please input pet weight',
      'phone':'Phone',
      'phone_tips':'Please input pet owner phone number',
      'email':'Email',
      'email_tips':'Please input pet owner email',
      'note':'Note',
      'note_tips':'Please write something to introduce your dog',
      'modify_key':'Modify Key',
      'modify_key_tips':"This Key is used to modify the public data,you can input any thing but you must remember it. if not you will can't modify the public data.",
      'modify_key_ruler':'Please input Modify key',
      'modify_info':'Modify Pet Info',
      'submit':'Submit',
      'birthday_select':'Select Birthday',
      'ok':'OK',
      'cancel':'Cancel',
      'call_onwer':'Call My Owner',
      'send_email':'Send Email',
      'contact_info':'Contact Info',
      'key_error':'Modify Key is Incorrect',


    }
  }